<template>
<div class="app">
  <div class="app__header">
    <button
      v-if="userUid"
      @click="logout"
      class="app__signout"
    >
      <IconSignOut class="app__signout-icon"/>
    </button>
  </div>
  <div class="app__login" v-if="!userUid">
        <form>
            <h2>Login</h2>
            <label for="email">
                Email
                <input class="app__input" v-model="userEmail" placeholder="E-Mail Adresse eingeben" type="email" id="email" required/>
            </label>
            <label for="password">
                Passwort
                <input class="app__input" v-model="userPassword" placeholder="Passwort Adresse eingeben" type="password" id="password" required/>
            </label>
            <button :disabled="!userEmail || !userPassword" class="app__button" @click.prevent="logInAsAdmin">Login</button>
            <div v-if="errorMessage" class="error-wrapper">
                {{errorMessage}}
            </div>
        </form>
    </div>
    <admin-dashboard
      v-if="getAdminLoggedIn"
      :userUid="userUid"
    />
    <guest-dashboard
      v-if="isLoggedInGuest"
      @noDataFound="logout"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
import AdminDashboard from './views/admin-dashboard.vue';
import GuestDashboard from './views/guest-dashboard.vue';
import IconSignOut from '@/components/icons/signout.vue';

export default {
  name: 'App',
  components: {
    GuestDashboard,
    AdminDashboard,
    IconSignOut,
  },
  data() {
    return {
      userEmail: '',
      userPassword: '',
      userUid: '',
      manualLogout: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapGetters([
      'getGuestLoggedIn',
      'getAdminLoggedIn',
      'getEventId',
      'getGuestId',
    ]),
    isLoggedInGuest() {
      return this.getGuestLoggedIn && !!this.getEventId && !!this.getGuestId;
    },
    validatedEventId() {
      return this.$route.query['event-id'] ? this.validateQueryParam(this.$route.query['event-id']) : '';
    },
    validatedGuestId() {
      return this.$route.query['guest-id'] ? this.validateQueryParam(this.$route.query['guest-id']) : '';
    },
    validIds() {
      return this.validatedEventId && this.validatedGuestId;
    },
    IdsInStore() {
      return this.getEventId && this.getGuestId;
    }
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if(user.isAnonymous) {
          if(this.IdsInStore) {
            this.setGuestLoggedIn(true);
            this.setAdminLoggedIn(false);
            this.userUid = user.uid;
          } else {
            this.logout();
            this.setAdminLoggedIn(false);
            this.setGuestLoggedIn(false);
            this.userUid = '';
          }
        } else {
          this.setAdminLoggedIn(true);
          this.setGuestLoggedIn(false);
          this.userUid = user.uid;
        }
      } else if (this.IdsInStore && !this.manualLogout) {
        this.guestLogIn();
      } else {
        this.setAdminLoggedIn(false);
        this.setGuestLoggedIn(false);
        this.userUid = '';
      }
    });
  },
  watch: {
    validIds(val) {
      if (val) {
        this.setEventId(this.validatedEventId);
        this.setGuestId(this.validatedGuestId);
      } else {
        this.logout()
      }
    }
  },
  methods: {
    ...mapActions([
      'guestLogIn',
      'setEventId',
      'setGuestId',
      'setGuestLoggedIn',
      'setAdminLoggedIn',
      'clearListeners',
    ]),
    logout() {
        this.clearListeners();
        this.manualLogout = true;
        if (this.getGuestLoggedIn) {
          firebase.auth().currentUser.delete().then(() => {
          }).catch(function(error) {
            console.log(error);
          });
        }
        if (this.getAdminLoggedIn) {
          firebase.auth().signOut().then(() => {
          }).catch((error) => {
              console.log(error);
          });
        }
        this.$router.push({query: ''});
    },
    validateQueryParam(query) {
      if (query.length !== 20) return '';
      return query;
    },
    logInAsAdmin() {
        this.errorMessage = '';
        firebase.auth().signInWithEmailAndPassword(this.userEmail, this.userPassword)
        .then((res) => {
            this.userUid = res.user.uid;
        })
        .catch((error) => {
            this.errorMessage = error.message;
        });
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html, body {
  font-size: 16px;
  margin: 0;
  padding: 1rem;
  background-image: linear-gradient(45deg, #cff5ee, #f1ffff);
  background-repeat: no-repeat;
  background-size: 120% 120%;
  padding: 0;
  margin:0;
  min-height: 100vh;
  width: 100%;
  overflow: auto;

  @media (min-width: 768px) {
    font-size: 19px;
  }
}
body {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  max-width: 1440px;
  margin: auto;
}
.app {
  &__header {
    position: relative;
    z-index: 1;
    margin-top: 1rem;
    padding: 0 1rem;
    
    @media (min-width: 1024px) {
      margin-top: 2rem;
    }
  }
  &__signout {
    background: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: inherit;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 50%;
    box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    cursor: pointer;
  }
  &__signout-icon {
    width: 1em;
    height: 1em;
  }
  &__login {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    max-width: 350px;
    margin: auto;
    form {
      display: flex;
      flex-direction: column;
      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }
    }
  }
  &__input {
    padding: .8rem 1rem;
    background-color: #f2f2f2;
    width: 100%;
    height: auto;
    font-size: inherit;
    font-family: inherit;
    border: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 1rem;
  }
  &__button {
    padding: .8rem 1rem;
    color: #ffffff;
    border-radius: 1rem;
    border: none;
    -webkit-appearance: none;
    outline: none;
    box-shadow: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    background-color: #05C793;
    transition: all 300ms;
    &:disabled {
      background-color: #f2f2f2;
      color: #b2b2b2;
    }
  }
}
</style>
