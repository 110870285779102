<template>
<div class="gauge" ref="gauge">
    <div class="gauge__content">
        <slot/>
    </div>
    <svg class="gauge__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <path class="gauge__path" d="M499.5 985.5c-65.5 0-129-12.8-188.8-38.1C253 923 201.1 888 156.6 843.4 112 798.9 77 747 52.6 689.3 27.3 629.5 14.5 566 14.5 500.5c0-65.5 12.8-129 38.1-188.8C77 254 112 202.1 156.6 157.6S253 78 310.7 53.6C370.5 28.3 434 15.5 499.5 15.5c65.5 0 129 12.8 188.8 38.1C746 78 797.9 113 842.4 157.6c44.6 44.5 79.6 96.4 104 154.1 25.3 59.8 38.1 123.3 38.1 188.8 0 65.5-12.8 129-38.1 188.8C922 747 887 798.9 842.4 843.4 797.9 888 746 923 688.3 947.4c-59.8 25.3-123.3 38.1-188.8 38.1z"/>
    </svg>
</div>
</template>
<script>
import { debounce } from 'lodash'
export default {
    name: 'Gauge',
    props: {
        max: {
            type: Number,
            default: 100,
        },
        value: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        mappedOffset() {
            const map = (value, in_min, in_max, out_min, out_max) => (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
            
            return map(this.value, 0, this.max, 3050, 0);
        },
    },
    watch: {
        value() {
            this.$nextTick(() => {
                this.setOffset(); 
            });
        }
    },
    created() {
        window.addEventListener('resize', debounce(() => {
                this.$nextTick(() => {
                    this.setBox();
                    this.setOffset();
                })
            })
        );
    },
    mounted() {
        setTimeout(() => {
            this.setBox();
            this.setOffset();
        },0)
    },
    methods: {
        setBox() {
            const { width } = this.$refs.gauge.getBoundingClientRect();
            this.$refs.gauge.style.setProperty('--container-height', `${width}px`);
        },
        setOffset() {
            setTimeout(() => {
                this.$refs.gauge.style.setProperty('--stroke-dashoffset', this.mappedOffset);
            }, 500)
        }
    }

}
</script>
<style lang="scss" scoped>
.gauge {
    --stroke-dashoffset: 3050;
    --container-height: 0;

    position: relative;
    width: 100%;
    height: var(--container-height);
    &__content {
        width: calc(100% - 20px);
        height: calc(100% - 20px);;
        background-color: #ffffff;
        box-shadow: 0px 0px 20px -10px rgb(120 120 120 / 70%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        flex-direction: column;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    &__svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform: rotate(180deg);
        overflow: visible
    }
    &__path {
        stroke-dasharray: 3050;
        stroke-dashoffset: var(--stroke-dashoffset);
        fill: transparent;
        stroke: #034f50;
        stroke-width: 50;
        transition: all 300ms;
        stroke-linecap: round;
    }
}
</style>