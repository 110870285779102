<template>
    <div class="guestslist">
        <ul class="guestlist__list guestlist__list--main">
            <li
                class="guestlist__item"
                v-for="(guest, i) in guests"
                :key="i"
                :class="{'guestlist__item--accepted': guest.accepted}"
            >
                <div class="guestlist__guest">
                    <div class="guestlist__guest-text">
                        {{guest.name}}
                        <small :class="{accepted: guest.accepted}">
                            {{guest.accepted ? 'Ich komme gerne!' : 'Ich kann leider nicht!'}}
                        </small>
                    </div>
                    <div class="guestlist__button-wrapper">
                        <button
                            class="guestlist__button"
                            :class="{'guestlist__button--accepted': guest.accepted}"
                            @click="emitSetInvitation(guest.id, !guest.accepted)"
                        >
                            <IconCheck/>
                        </button>
                        <button
                            v-if="admin"
                            class="guestlist__button guestlist__button--edit"
                            :class="{'guestlist__button--active': guest.editable}"
                            @click="guest.editable = !guest.editable"
                        >
                            <IconPen/>
                        </button>
                        <button
                            v-if="admin"
                            class="guestlist__button guestlist__button--remove"
                            @click="emitRemoveGuest(guest.id)"
                        >
                            <IconTimes/>
                        </button>
                    </div>
                </div>
                <ul class="guestlist__list">
                    <li
                        v-for="add in guest.adds"
                        :key="add"
                        class="guestlist__subitem"
                        :class="{'guestlist__subitem--grey': !guest.accepted}"
                    >
                        <div class="guestlist__guest">
                            <div>{{add}}</div>
                            <div class="guestlist__button-wrapper">
                                <button
                                    class="guestlist__button"
                                    :class="{'guestlist__button--remove': guest.accepted}"
                                    @click="emitRemovePlusOne(guest.id, add)"
                                >
                                    <IconTimes/>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li v-if="guest.editable || !admin" ref="add" class="guestlist__subitem">
                        <div class="guestlist__addguest">
                            <input class="guestlist__addguest-input" placeholder="Bringst du noch jemanden mit?" type="text" v-model="guest.add"/>
                            <div class="guestlist__button-wrapper">
                                <button
                                    class="guestlist__button guestlist__button--add"
                                    @click="emitAddPlusOne(guest.id, guest.add)"
                                >
                                    <IconPlus/>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
            <li v-if="admin" class="guestlist__item">
                <div class="guestlist__addguest">
                    <input class="guestlist__addguest-input" placeholder="Wen willst du einladen?" type="text" v-model="newGuest"/>
                    <div class="guestlist__button-wrapper">
                        <button
                            class="guestlist__button guestlist__button--add"
                            :disabled="!newGuest"
                            @click="emitAddGuest(newGuest)"
                        >
                            <IconPlus/>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import IconCheck from '@/components/icons/check.vue'
import IconTimes from '@/components/icons/times.vue'
import IconPlus from '@/components/icons/plus.vue'
import IconPen from '@/components/icons/pen.vue'
export default {
    name: 'GuestList',
    components: {
        IconCheck,
        IconTimes,
        IconPlus,
        IconPen
    },
    props: {
        admin: {
            type: Boolean,
            default: false,
        },
        guests: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newGuest: '',
        }
    },
    methods: {
        emitAddPlusOne(id, add) {
            this.$emit('onSetPlusOne', id, add);
            this.$nextTick(() => {
                this.$refs.add.scrollIntoView();
            })
        },
        emitRemovePlusOne(id, add) {
            this.$emit('onRemovePlusOne', id, add);

        },
        emitSetInvitation(id, bool) {
            this.$emit('setInvitation', id, bool);
        },
        emitRemoveGuest(id) {
            this.$emit('onRemoveGuest', id);
        },
        emitAddGuest() {
            this.$emit('onAddGuest', this.newGuest);
            this.newGuest = '';
        },
    }
}
</script>
<style lang="scss" scoped>
.guestlist {
    &__list {
        padding: 0;
        margin: 0;
    }
    &__item, &__subitem {
        list-style-type: none;
        margin: 0;
        padding: 0;
        transition: all 300ms;
        &--grey {
            opacity: .5;
        }
    }
    &__guest, &__addguest {
        padding: .8rem 1rem;
        background-color: #ffffff;
        border-radius: 1rem;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
        small {
            color: #D24B69;
            &.accepted {
                color: #05C793
            }
            @media (min-width: 768px) {
                margin-left: 1rem;
            }
            
        }
    }
    &__button-wrapper {
        margin: 0 0 0 auto;
        white-space: nowrap;
    }
    &__button {
        border: 0;
        box-shadow:0;
        -webkit-appearance: none;
        background-color: #e2e2e2;
        border-radius: 50%;
        padding: .5rem;
        height: 32px;
        width: 32px;
        cursor: pointer;
        transition: all 300ms;
        color: #b2b2b2;
        margin-left: .5rem;
        @media (min-width: 768px) {
            padding: .7rem;
            height: 40px;
            width: 40px;
        }
        svg {
            height: 100%;
            width: 100%;
        }
        &:hover {
            background-color: #d9d9d9;
            color: #b9b9b9;
        }
        &--accepted {
            background-color: #05C793;
            color: #f2f2f2;
            &:hover {
                background-color: #05C793;
                color: #f2f2f2;
                opacity: .6;
            }
        }
        &--active {
            background-color: #f4d06f;
            color: #f2f2f2;
            &:hover {
                background-color: #f4d06f;
                color: #f2f2f2;
                opacity: .6;
            }
        }
        &--remove {
            background-color: #D24B69;
            color: #f2f2f2;
            &:hover {
                background-color: #D24B69;
                color: #f2f2f2;
                opacity: .6;
            }
        }
    }
    &__guest-text {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        @media (min-width: 768px) {
            align-items: center;
            flex-direction: row;
        }
    }
    &__addguest-input {
        width: 100%;
        height: auto;
        font-size: inherit;
        font-family: inherit;
        background: transparent;
        border: none;
        -webkit-appearance: none;
        outline: none;
    }
}
</style>
