<template>
    <div v-if="dataFetched" class="admin-dashboard">
        <div class="admin-dashboard__wrapper">
            <div class="admin-dashboard__gridelement admin-dashboard__description">
                <h1>Hey {{userData.name.split(' ')[0]}},</h1>
                <textarea
                    v-model="eventData.description"
                    placeholder="Hier kannst du ein paar Hinweise reinschreiben"
                    class="admin-dashboard__textarea"
                    @blur="setDescription"
                />
            </div>
            <div class="admin-dashboard__location admin-dashboard__gridelement">
                <div class="admin-dashboard__location-overlay">
                    <h2>Wo?</h2>
                    <ul class="admin-dashboard__address-list">
                    <li class="admin-dashboard__address-list-item">
                        {{eventData.location.name}}
                    </li>
                    <li class="admin-dashboard__address-list-item">
                        {{eventData.location.street}}
                    </li>
                    <li class="admin-dashboard__address-list-item">
                        {{eventData.location.city}}
                    </li>
                    <li class="admin-dashboard__address-list-item">
                        {{eventData.location.country}}
                    </li>
                    </ul>
                    <a class="admin-dashboard__mapslink" :href="eventData.location.mapsLink">In Maps öffnen</a>
                </div>
                <GoogleMaps class="admin-dashboard__location-map" :overlayed="true" :geo="eventData.location.geo" :link="eventData.location.mapsLink"/>
            </div>
            <div class="admin-dashboard__guestprocess admin-dashboard__gridelement">
                <h2>Wie viele?</h2>
                <Gauge :max="totalGuests" :value="acceptedGuests">
                    <strong>{{`${acceptedGuests} / ${totalGuests}`}}</strong>
                    <small v-if="declinedGuests !== totalGuests" class="admin-dashboard__declined">abgelehnt: {{declinedGuests}}</small>
                </Gauge>
            </div>
            <div class="admin-dashboard__counter admin-dashboard__gridelement">
                <h2>Wann?</h2>
                <CountDown
                    class="admin-dashboard__countdown"
                    v-if="eventData.date"
                    :timestamp="eventData.date.timestamp.seconds"
                    :text="eventData.date.date"
                />
            </div>
            <div class="admin-dashboard__guestlist admin-dashboard__gridelement">
                <h2>Wer?</h2>
                <GuestList
                    :guests="sortedGuests"
                    :admin="true"
                    @onSetPlusOne="setPlusOne"
                    @onRemovePlusOne="removePlusOne"
                    @onRemoveGuest="removeGuest"
                    @onAddGuest="addGuest"
                    @setInvitation="setInvitation"
                />
            </div>
        </div>
        <div class="admin-dashboard__circle admin-dashboard__circle--top-right">
            <Bubble/>
        </div>
        <div class="admin-dashboard__circle admin-dashboard__circle--bottom-left">
            <BubbleCurve/>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
import GuestList from '@/components/guest-list.vue'
import CountDown from '@/components/countdown.vue'
import GoogleMaps from '@/components/google-maps.vue'
import Gauge from '@/components/gauge.vue'
import Bubble from '@/components/bubble.vue';
import BubbleCurve from '@/components/bubble-curve.vue';

export default {
    name: 'admin-dashboard',
    components: {
        GuestList,
        CountDown,
        GoogleMaps,
        Gauge,
        Bubble,
        BubbleCurve,
    },
    props: {
        userUid: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            userData: {},
            eventData: {},
            guests: [],
            errorMessage: '',
        }
    },
    computed: {
        ...mapGetters([
            'getAdminLoggedIn',
            'getEventId'
        ]),
        userRef() {
            return this.userUid ? firebase.firestore().collection('users').doc(this.userUid) : '';
        },
        eventRef() {
            return this.userData.events[0] ? firebase.firestore().collection('events').doc(this.userData.events[0]) : '';
        },
        guestListRef() {
            return firebase.firestore().collection(`events/${this.userData.events[0]}/guests/`);
        },
        totalGuests() {
            const totalAdds = [];
            this.guests.map((guest) => {
                if(guest.adds.length) {
                    guest.adds.map(add => {
                        totalAdds.push(add);    
                    })
                }
            });

            return this.guests.length + totalAdds.length;
        },
        acceptedGuests() {
            const acceptedGuests = this.guests.filter(guest => guest.accepted);
            const totalAdds = [];
            acceptedGuests.map((guest) => {
                if(guest.adds.length) {
                    guest.adds.map(add => {
                        totalAdds.push(add);    
                    })
                }
            });

            return acceptedGuests.length + totalAdds.length;
        },
        declinedGuests() {
            const declinedGuests = this.guests.filter(guest => !guest.accepted);
            const totalAdds = [];
            declinedGuests.map((guest) => {
                if(guest.adds.length) {
                    guest.adds.map(add => {
                        totalAdds.push(add);    
                    })
                }
            });

            return declinedGuests.length + totalAdds.length;
        },
        dataFetched() {
            return !!Object.keys(this.eventData).length && !!Object.keys(this.userData).length;
        },
        sortedGuests() {
            return this.guests.slice(0).sort((a, b) => a.timestamp - b.timestamp); // slice(0) create a dublicate of this.guests
        }
    },
    mounted() {
        this.getUserData();
    },
    watch: {
        userData() {
            this.getEventSpecs();
            this.getGuestList();
        },
    },
    methods: {
        ...mapActions([
            'addListener'
        ]),
        getUserData() {
            this.userRef.get().then(doc => {
                this.userData = doc.data();
            })
        },
        getEventSpecs() {
            this.addListener(this.eventRef.onSnapshot(doc => {
                const data = doc.data();
                this.eventData = data;
            }));
        },
        getGuestList() {
            this.addListener(this.guestListRef
                .onSnapshot((querySnapshot) => {
                    const guests = [];
                    querySnapshot.forEach((doc) => {
                        const guest = {
                            id: doc.id,
                            ...doc.data(),
                        }
                        guests.push(guest);
                    });
                    this.guests = guests;
            }));
        },
        setPlusOne(id, add) {
            this.guestListRef.doc(id)
            .update({
                adds: firebase.firestore.FieldValue.arrayUnion(add)
            }).then(() => {
                add = ''
            })
        },
        removePlusOne(id, add) {
            this.guestListRef.doc(id)
            .update({
                adds: firebase.firestore.FieldValue.arrayRemove(add)
            })
        },
        addGuest(name) {
            const guest = {
                name,
                adds: [],
                accepted: false,
                timestamp: new Date(),
            }
            this.guestListRef.add(guest).then(res => {
                this.guestListRef.doc(res.id).set({
                    id: res.id,
                }, {merge: true})
            });
        },
        removeGuest(id) {
            this.guestListRef.doc(id).delete();
        },
        setInvitation(id, val) {
            this.guestListRef.doc(id).set({
                accepted: val,
            }, { merge: true });
        },
        setDescription() {
            this.eventRef.set({
                description: this.eventData.description
            }, { merge: true });
        }
    }
}
</script>
<style lang="scss" scoped>
.admin-dashboard {
    position: relative;
    padding: 1rem;
    height: auto;
    &__wrapper {
        background-color: rgba(255,255,255,0.65);
        backdrop-filter: blur(10px);
        box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
        border-radius: 20px;
        display: grid;
        grid-gap:1rem;
        margin-bottom: 60px;
        padding: 1rem;
        position: relative;
        z-index: 1;

        @media (min-width: 320px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 
            'textarea textarea'
            'who when'
            'where where'
            'guests guests';
        }
        @media(min-width: 768px) {
            grid-gap: 2rem;
        }
        @media (min-width: 1440px) {
            grid-template-columns: repeat(8, 1fr);
            grid-template-areas: 
            'textarea textarea textarea textarea textarea textarea textarea textarea'
            'who who when when where where where where'
            'guests guests guests guests guests guests guests guests';
        }
    }

    &__gridelement {
        padding: 1rem;
        border-radius: 12px;
        @media (min-width: 768px) {
            padding: 2rem;
        }
    }
    
    
    &__description {
        display: flex;
        flex-direction: column;
        grid-area: textarea;
    }

    &__location {
        overflow: hidden;
        padding: 0;
        position: relative;
        grid-area: where;
        min-height: 300px;
        box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    }
    &__location-overlay {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        position: absolute;
        padding: 2rem;
        background-color: #ffffff;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 1;
    }

    &__address-list {
        padding: 0;
        margin: 0;
    }
    &__address-list-item {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    &__mapslink {
        margin: auto 0 0 0;
    }

    &__guestlist {
        overflow: auto;
        max-height: 600px;
        grid-area: guests;
    }
    &__guestprocess {
            grid-area: who;
        }
        &__counter {
            grid-area: when;
        }

    &__textarea {
        width: 100%;
        height: auto;
        flex: 1;
        min-height: 350px;
        background-color: #ffffff;
        border: 0;
        border-radius: 1rem;
        padding: 1rem;
        font-size: inherit;
        font-family: inherit;
        resize: none;
        box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    }
    &__counter {
        display: flex;
        flex-direction: column;
    }
    &__countdown {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
    &__declined {
        color: #D24B69;
    }
    &__circle {
      position: absolute;
      z-index: 0;
      width: 400px;
      height: 400px;
      &--top-right {
          top: -200px;
          z-index: 0;
          right: -200px;
          color: #92d1c8;
      }
      &--bottom-left {
          bottom: -200px;
          z-index: 0;
          left: -200px;
          color: #50a5a6;
      }
  }
}
</style>