import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import firebase from 'firebase/app'

// Firebase App (the core Firebase SDK) is always required and must be listed first

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: 'AIzaSyBKvexiLw27LvmIsENaMw1Xg44uhPkal-k',
    authDomain: 'wedding-6ffad.firebaseapp.com',
    databaseURL: 'https://wedding-6ffad.firebaseio.com',
    projectId: 'wedding-6ffad',
    storageBucket: 'wedding-6ffad.appspot.com',
    messagingSenderId: '1036208234604',
    appId: '1:1036208234604:web:ecd0f04a1e058a0a977849',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount('#app')
