<template>
  <div
    v-if="dataFetched"
    class="guest-dashboard"
  >
    <div class="guest-dashboard__wrapper">
      <div class="guest-dashboard__gridelement guest-dashboard__intro">
        <h1>Hey {{guestData.name.split(' ')[0]}},</h1>
        <div class="guest-dashboard__description">
          {{eventData.description}}
        </div>
      </div>
      <div class="guest-dashboard__gridelement guest-dashboard__counter">
        <h2>Wann?</h2>
        <CountDown class="guest-dashboard__countdown" :timestamp="eventData.date.timestamp.seconds" :text="eventData.date.date" />
      </div>
      <div class="guest-dashboard__gridelement guest-dashboard__guest">
        <h2>Wer?</h2>
        <GuestList
          class="guest-dashboard__guest-list"
          :guests="[guestData]"
          @onSetPlusOne="setPlusOne"
          @onRemovePlusOne="removePlusOne"
          @setInvitation="setInvitation"
        />
      </div>
      <div class="guest-dashboard__gridelement guest-dashboard__location">
        <div class="guest-dashboard__location-overlay">
          <h2>Wo?</h2>
          <ul class="guest-dashboard__address-list">
            <li class="guest-dashboard__address-list-item">
              {{eventData.location.name}}
            </li>
            <li class="guest-dashboard__address-list-item">
              {{eventData.location.street}}
            </li>
            <li class="guest-dashboard__address-list-item">
              {{eventData.location.city}}
            </li>
            <li class="guest-dashboard__address-list-item">
              {{eventData.location.country}}
            </li>
          </ul>
          <a class="guest-dashboard__mapslink" :href="eventData.location.mapsLink">In Maps öffnen</a>
        </div>
        <GoogleMaps class="guest-dashboard__location-map" :overlayed="true" :geo="eventData.location.geo" :link="eventData.location.mapsLink"/>
      </div>
    </div>
    <div class="guest-dashboard__circle guest-dashboard__circle--top-right">
      <Bubble/>
    </div>
    <div class="guest-dashboard__circle guest-dashboard__circle--bottom-left">
      <BubbleCurve/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GuestList from '@/components/guest-list.vue'
import CountDown from '@/components/countdown.vue' 
import GoogleMaps from '@/components/google-maps.vue'
import Bubble from '@/components/bubble.vue';
import BubbleCurve from '@/components/bubble-curve.vue';
import firebase from 'firebase'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GuestDashboard',
  components: {
    GuestList,
    CountDown,
    GoogleMaps,
    Bubble,
    BubbleCurve,
  },
  data() {
    return {
      guestData: {},
      eventData: {},
      mapSpecs: {},
      testArr: ['1', '2'],
    }
  },
  computed: {
    ...mapGetters([
      'getGuestLoggedIn',
      'getEventId',
      'getGuestId',
    ]),
    guestRef() {
      return firebase.firestore().collection(`events/${this.getEventId}/guests/`).doc(this.getGuestId);
    },
    eventRef() {
      return firebase.firestore().collection('events').doc(this.getEventId);
    },
    dataFetched() {
      return !!Object.keys(this.eventData).length && !!Object.keys(this.guestData).length;
    },
  },
  mounted() {
    this.getGuestData();
    this.getEventData();
  },
  watch: {
    dataFetched(val) {
      if(val) {
        this.$nextTick(() => {
          this.$emit('dataFetched');
        })
      }
    },
  },
  methods: {
    ...mapActions([
      'addListener',
    ]),
    async getGuestData() {
      await this.addListener(this.guestRef
        .onSnapshot((doc) => {
            if(doc.data()) {
              this.guestData = doc.data();
            } else {
              this.$emit('noDataFound');
            }
        }));
    },
    async getEventData() {
      await this.addListener(this.eventRef.onSnapshot(doc => {
        if(doc.data()) {
              this.eventData = doc.data();
            } else {
              this.$emit('noDataFound');
            }
      }));
    },
    setInvitation(id, val) {
      this.guestRef.set({
        accepted: val,
      }, { merge: true });
    },
    setPlusOne(id, add) {
        this.guestRef
        .update({
            adds: firebase.firestore.FieldValue.arrayUnion(add)
        }).then(() => {
            add = ''
        })
    },
    removePlusOne(id, add) {
        this.guestRef
        .update({
            adds: firebase.firestore.FieldValue.arrayRemove(add)
        })
    },
  }
}
</script>
<style lang="scss" scoped>
.guest-dashboard {
  position: relative;
  padding: 1rem;
  height: 100%;
  background-size: 400px 400px;
  background-position: top right;
  &__wrapper {
    background-color: rgba(255,255,255,0.65);
    backdrop-filter: blur(10px);
    box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    border-radius: 20px;
    display: grid;
    margin-bottom: 60px;
    padding: 1rem;
    position: relative;
    z-index: 1;
    grid-gap:2rem;
    
    @media (min-width: 320px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
        'desc desc'
        'when when'
        'loc loc'
        'who who';
    }
    @media (min-width: 768px) {
      padding: 2rem;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 
        'desc desc'
        'when when'
        'loc loc'
        'who who';
    }
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: 
        'desc desc desc'
        'when loc loc'
        'who who who';
    }
    @media (min-width: 1440px) {
      grid-template-columns: repeat(6, 1fr);
      grid-template-areas: 
        'desc desc desc desc when when'
        'who who who loc loc loc';
    }
  }

  h1, h2 {
      margin-top: 0;
  }

  &__gridelement {
    border-radius: 12px;
  }
  &__location {
    grid-area: loc;
    position: relative;
    padding: 0;
    min-height: 250px;
    box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    overflow: hidden;
  }
  &__location-overlay {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    padding: 2rem;
    background-color: #ffffff;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    @media (min-width: 470px) {
      width: auto;
    }
  }
  &__location-map {
    display: none;
    @media (min-width: 470px) {
      display: block;
    }
  }
  &__address-list {
    padding: 0;
    margin: 0;
  }
  &__address-list-item {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  &__mapslink {
    margin: auto 0 0 0;
  }
  &__guest {
    grid-area: who;
  }
  &__guest-list {
    max-height: 300px;
    overflow: auto;
  }
  &__intro {
    grid-area: desc;
  }
  &__counter {
    grid-area: when;
    display: flex;
    flex-direction: column;
    min-height: 250px;

  }
  &__countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 1rem;
    max-width: 350px;
    max-height: 350px;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &__description {
    white-space: pre-line;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px -10px rgb(120 120 120 / 30%);
    padding: 1rem;
    border-radius: 20px;
    @media (min-width: 1024px) {
      padding: 2rem;
    }
  }
  &__circle {
      position: absolute;
      z-index: 0;
      width: 400px;
      height: 400px;
      &--top-right {
          top: -150px;
          z-index: 0;
          right: -150px;
          color: #92d1c8;
      }
      &--bottom-left {
          bottom: -150px;
          z-index: 0;
          left: -150px;
          color: #50a5a6;
      }
  }
}
</style>
