<template>
    <div class="countdown">
        <Gauge :max="365" :value="daysToDate">
            <span>{{text}}</span>
            <strong>{{countdown}}</strong>
        </Gauge>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import Gauge from '@/components/gauge.vue'
export default {
    name: 'CountDown',
    components: {
        Gauge
    },
    props: {
        timestamp: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            default: '',
        }
    },
    computed: {
        date() {
            return dayjs.unix(this.timestamp).format('DD.MM.YYYY');
        },
        time() {
            return dayjs.unix(this.timestamp).format('HH:mm');
        },
        daysToDate() {
            return dayjs.unix(this.timestamp).diff(dayjs(), 'day');
        },
        hoursToDate() {
            return dayjs.unix(this.timestamp).diff(dayjs(), 'hours');
        },
        countdown() {
        if (this.hoursToDate > 24) {
            if(this.daysToDate === 1) {
            return `Noch ${this.daysToDate} Tag`;
            }
            return `Noch ${this.daysToDate} Tage`;
        }
        if(this.hoursToDate === 1) {
            return `Noch ${this.hoursToDate} Stunde`;
        }
        return `Noch ${this.hoursToDate} Stunden`;
        }
    }
}
</script>