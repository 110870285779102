import { createStore } from 'vuex'
import firebase from 'firebase'

export default createStore({
  state: {
    adminLoggedIn: false,
    guestLoggedIn: false,
    eventId: '',
    guestId: '',
    listeners: [],
  },
  getters: {
    getGuestLoggedIn: state => state.guestLoggedIn,
    getAdminLoggedIn: state => state.adminLoggedIn,
    getEventId: state => state.eventId,
    getGuestId: state => state.guestId,

  },
  mutations: {
    setGuestLoggedIn(state, payload) {
      state.guestLoggedIn = payload;
    },
    setAdminLoggedIn(state, payload) {
      state.adminLoggedIn = payload;
    },
    setEventId(state, payload) {
      state.eventId = payload;
    },
    setGuestId(state, payload) {
      state.guestId = payload;
    },
    addListener(state, payload) {
      state.listeners.push(payload);
    },
    clearListeners(state) {
      state.listeners.forEach(unsub => unsub());
    }
  },
  actions: {
    guestLogIn() {
      firebase.auth().signInAnonymously()
        .catch((error) => {
          console.log(error)
        });
    },
    setEventId({ commit }, payload) {
      commit('setEventId', payload);
    },
    setGuestId({ commit }, payload) {
      commit('setGuestId', payload);
    },
    setAdminLoggedIn({ commit }, payload) {
      commit('setAdminLoggedIn', payload);
    },
    setGuestLoggedIn({ commit }, payload) {
      commit('setGuestLoggedIn', payload);
    },
    addListener({commit}, payload) {
      commit('addListener', payload);
    },
    clearListeners({ commit }) {
      commit('clearListeners');
    }
  },
})
