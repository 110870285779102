<template>
    <a class="google-maps" ref="map" :href="link" target="_blank">
        <img class="google-maps__image" :src="mapSpecs.src"/>
    </a>
</template>
<script>
import { debounce } from 'lodash'
export default {
    name: 'GoogleMaps',
    props: {
        geo: {
            type: Object,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        overlayed: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            mapSpecs: {},
        }
    },
    computed: {
        eventLocation() {
            if (Object.keys(this.geo).length) {
                const position =  {
                    lat: this.geo._lat,
                    lng: this.geo._long,
                }
                return position;
            }
            return false;
        },
    },
    created() {
        window.addEventListener('resize', debounce(() => {
                this.setMapWidth();
            })
        );
    },
    mounted() {
        setTimeout(() => {
            this.setMapWidth();
        }, 100);
    },
    watch: {
        mapSpecs() {
            this.$nextTick(() => {
                this.initMap()
            });
        }
    },
    methods: {
        initMap() {
            const center = {
                long: this.overlayed ? this.eventLocation.lng - .08 : this.eventLocation.lng,
                lat: this.eventLocation.lat,
            }
            this.mapSpecs.src = `https://maps.googleapis.com/maps/api/staticmap?&zoom=11&size=${this.mapSpecs.width}x${this.mapSpecs.height}&markers=${this.eventLocation.lat},${this.eventLocation.lng}&center=${center.lat},${center.long}&key=AIzaSyD_wu7nf-lhAQdYLTdBN0EQ6wvnMJVZqLI`
        },
        setMapWidth() {
            const {width, height } = this.$refs.map.getBoundingClientRect();
            const floored = val => Math.floor(val); 
            this.mapSpecs = {
                width: floored(width),
                height: floored(height),
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.google-maps {
    width: 100%;
    height: 100%;
    position: absolute;
    &__image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
  }
</style>